import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "cp-icon-button",
	templateUrl: "./icon-button.component.html",
	styleUrl: "./icon-button.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconButtonComponent {
	@Input({ required: true }) icon!: string;
	@Input() disabled = false;
	@Input() isActive = false;
	@Input() withBackground = true;

	@Output() readonly clicked = new EventEmitter<void>();
}
