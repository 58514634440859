<button
	class="block rounded-lg p-2"
	[disabled]="disabled"
	[matRippleDisabled]="disabled"
	[ngClass]="{ 'border-primary text-primary border': isActive, '!bg-white/60': withBackground }"
	(click)="clicked.emit()"
	matRipple
>
	<eth-icon class="text-2xl" [name]="icon"></eth-icon>
</button>
